/** @jsx jsx */
import { jsx } from 'theme-ui'
import ListingCard from '~/components/Listing/Card'
import PackCardAddToCart from '~/components/Pack/AddToCart/Card'
import PackCallout from '~/components/Pack/Callout'

const PackCard = ({ pack, hasGiftMessage = false }) => {
  const {
    cardTag,
    hoverImage,
    mainImage,
    name,
    reviewlessCallout,
    reviewsPack,
    shortDescription,
    slug,
    slugUrl,
    subtitle,
  } = pack

  return (
    <ListingCard
      addToCart={<PackCardAddToCart pack={pack} />}
      callout={<PackCallout pack={pack} />}
      hasGiftMessage={hasGiftMessage}
      hoverImage={hoverImage}
      mainImage={mainImage}
      name={name}
      reviewlessCallout={reviewlessCallout}
      reviewsSlug={reviewsPack?.slug}
      shortDescription={shortDescription}
      slug={slug}
      slugUrl={slugUrl}
      subtitle={subtitle}
      tag={cardTag}
    />
  )
}

export default PackCard
