import React from 'react'
import { Button } from 'theme-ui'

const LandingPageJumpCta = ({ sx, target, value, variant }) => {
  return (
    <Button
      variant={variant}
      sx={sx}
      onClick={(e) =>
        target?.current?.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })
      }
    >
      {value}
    </Button>
  )
}

export default LandingPageJumpCta
