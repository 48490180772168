/** @jsx jsx */
import { Box, Heading, jsx, Text } from 'theme-ui'
import ListingAddToCheckoutButton from '~/components/Listing/AddToCart/Checkout'
import ListingCardImage from '~/components/Listing/Card/Image'

const ListingCardLanding = ({
  bottleQuantity,
  callout,
  canQuantity,
  comingSoon,
  hoverImage,
  mainImage,
  name,
  postPromoPrice,
  products,
  promoCode,
  sku,
  soldOut,
}) => {
  return (
    <Box
      mx="auto"
      sx={{
        alignItems: 'left',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxWidth: '475px',
        textAlign: 'left',
        width: '100%',
      }}
    >
      <Box>
        <ListingCardImage
          callout={callout}
          hoverImage={hoverImage}
          isLinked={false}
          mainImage={mainImage}
        />
        <Box mt={2} px={1}>
          <Heading as="h2" variant="title4" mb={2}>
            {name}
          </Heading>
          <Text as="p" variant="body3" mb={2}>
            <Text as="strong">{'Includes: '}</Text>
            <Text as="span">
              {products.map((product) => product.name).join(', ')}
            </Text>
          </Text>
          <Text as="p" variant="body3">
            <Text as="strong">{`${canQuantity} cans = ${bottleQuantity} bottles`}</Text>
          </Text>
        </Box>
      </Box>
      <Box mt={3}>
        <ListingAddToCheckoutButton
          comingSoon={comingSoon}
          postPromoPrice={postPromoPrice}
          promoCode={promoCode}
          sku={sku}
          soldOut={soldOut}
        />
      </Box>
    </Box>
  )
}

export default ListingCardLanding
