import React, { useRef } from 'react'
import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import LandingPage from '~/components/LandingPage'
import LandingPageJumpCta from '~/components/LandingPage/JumpCta'
import NotificationSaleBar from '~/components/Notification/SaleBar'
import StructuredLandingPageData from '~/components/StructuredData/LandingPage'
import { stripHtml } from '~/utils/formatters'

const LandingPageTemplate = ({ data }) => {
  const { landingPage } = data
  const {
    description,
    headerCta,
    heroImage,
    noindex,
    notificationSaleBar,
    title,
  } = landingPage
  const ctaTarget = useRef(null)

  return (
    <Layout
      navJumpCta={
        <LandingPageJumpCta
          sx={{
            borderWidth: '2px',
            pt: '10px',
            pb: '13px',
            px: [1, 4],
            '@media only screen and (min-width: 375px) and (max-width: 639px)':
              {
                px: 2,
              },
          }}
          target={ctaTarget}
          value={headerCta}
          variant="secondary"
        />
      }
    >
      <Metadata
        title={title.replace('_', ' ')}
        description={stripHtml(description?.childMarkdownRemark?.html)}
        image={getSrc(heroImage)}
        noindex={noindex}
      />
      <StructuredLandingPageData landingPage={landingPage} />
      <NotificationSaleBar
        isPageSpecific={true}
        pageNotification={notificationSaleBar}
      />
      <LandingPage landingPage={landingPage} ctaTarget={ctaTarget} />
    </Layout>
  )
}

export const landingPageQuery = graphql`
  query LandingPageQuery($slug: String!, $locale: String) {
    landingPage: contentfulLandingPage(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...LandingPageFragment
    }
  }
`

export default LandingPageTemplate
