/** @jsx jsx */
import { jsx } from 'theme-ui'
import ListingCardLanding from '~/components/Listing/Card/Landing'
import PackCallout from '~/components/Pack/Callout'
import { getKitCanCount, getUniqueKitProducts } from '~/utils/kits'
import { getLandingPagePostPromoPrice } from '~/utils/landing-pages'
import { getAvailablePackKits } from '~/utils/packs'
import { getFormattedBottleCount } from '~/utils/products'

const PackCardLanding = ({ landingPage, pack }) => {
  const { promoCode } = landingPage
  const { comingSoon, mainImage, hoverImage, name, soldOut } = pack
  const availableKits = getAvailablePackKits(pack)
  const kit = availableKits[0]
  const { price, sku } = kit

  const canQuantity = getKitCanCount(kit)
  const bottleQuantity = getFormattedBottleCount(canQuantity)
  const products = getUniqueKitProducts(kit)
  const postPromoPrice = getLandingPagePostPromoPrice(landingPage, price)

  return (
    <ListingCardLanding
      bottleQuantity={bottleQuantity}
      canQuantity={canQuantity}
      comingSoon={comingSoon}
      hoverImage={hoverImage}
      mainImage={mainImage}
      name={name}
      postPromoPrice={postPromoPrice}
      products={products}
      promoCode={promoCode}
      sku={sku}
      soldOut={soldOut}
      callout={<PackCallout pack={pack} />}
    />
  )
}

export default PackCardLanding
