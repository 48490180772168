/** @jsx jsx */
import { Box, Flex, Heading, jsx, Text } from 'theme-ui'
import ListingCardImage from '~/components/Listing/Card/Image'
import ListingGiftMessage from '~/components/Listing/GiftMessage'
import ProductLink from '~/components/Product/Link'
import LinkedReviewStars from '~/components/Reviews/LinkedStars'

const ListingCard = ({
  addToCart,
  callout,
  hasGiftMessage,
  hoverImage,
  mainImage,
  name,
  reviewlessCallout,
  reviewsSlug,
  shortDescription,
  slug,
  slugUrl,
  subtitle,
  tag,
}) => {
  return (
    <Box
      mx="auto"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'left',
        flexDirection: 'column',
        maxWidth: '400px',
        textAlign: 'left',
        width: '100%',
      }}
    >
      <Flex
        sx={{
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <ListingCardImage
          callout={callout}
          hoverImage={hoverImage}
          mainImage={mainImage}
          name={name}
          slug={slug}
          slugUrl={slugUrl}
          tag={tag}
        />
        <Flex
          mt={2}
          px={1}
          sx={{
            alignItems: 'left',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <Heading as="h2" variant="title5" mb="4px">
            <ProductLink
              slug={slug}
              slugUrl={slugUrl}
              sx={{
                color: 'black',
                display: 'block',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              {name}
            </ProductLink>
          </Heading>
          {subtitle && (
            <Text
              as="h3"
              variant="body3"
              mb={1}
              mr={-1}
              sx={{
                color: 'greyDark',
                fontWeight: 'normal',
              }}
            >
              {subtitle}
            </Text>
          )}
          {reviewlessCallout && (
            <Text as="p" mb="2px" variant="callout3" color="brandTertiary">
              {reviewlessCallout}
            </Text>
          )}
          {!reviewlessCallout && (
            <LinkedReviewStars
              id={reviewsSlug || slug}
              mb="4px"
              mt="4px"
              to={`/products/${slugUrl || slug}/`}
            />
          )}
          <Text as="p" variant="body3" mt={1}>
            {shortDescription}
          </Text>
        </Flex>
      </Flex>
      <Box mt={2} px={1}>
        {hasGiftMessage && (
          <Box mb={2}>
            <ListingGiftMessage />
          </Box>
        )}
        {addToCart}
      </Box>
    </Box>
  )
}

export default ListingCard
