/** @jsx jsx */
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { navigate } from 'gatsby'
import moment from 'moment'
import { jsx, Button, Spinner, Text } from 'theme-ui'
import { useCart, useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { useAddSubscriptionToCart } from '~/hooks/components/use-add-subscription-to-cart'
import { useAddToCart } from '~/hooks/components/use-add-to-cart'
import { useEmptyCart } from '~/hooks/components/use-empty-cart'
import { addPromoCodesToStorage } from '~/hooks/components/use-promo-codes'
import { toUsdCurrency } from '~/utils/formatters'

const ListingAddToCheckoutButton = ({
  comingSoon = false,
  includePrice = true,
  isClubSignup,
  label,
  postPromoPrice,
  preorderDate = null,
  promoAutoApply,
  promoCode,
  sku,
  soldOut = false,
}) => {
  const { addSubscriptionToCart } = useAddSubscriptionToCart()
  const { getAddToCartDataBySku } = useAddToCart()
  const { addPromoCode, addToCart, cart } = useCart()
  const { emptyCart } = useEmptyCart()
  const form = useForm()
  const [buttonIsLoading, setButtonIsLoading] = useState(false)
  const [apiError, setApiError] = useState(null)
  const translate = useTranslate()

  const { handleSubmit } = form

  const { parts, price } = getAddToCartDataBySku(sku) || {}

  if (promoAutoApply && promoCode) {
    addPromoCodesToStorage([promoCode])
  }

  let displayLabel = translate('product.add_to_cart')
  if (comingSoon) {
    displayLabel = translate('product.coming_soon')
  } else if (soldOut) {
    displayLabel = translate('product.sold_out')
  } else if (label) {
    displayLabel = label
  } else if (preorderDate && moment(preorderDate).isAfter(moment())) {
    displayLabel = translate('product.preorder')
  }

  const onSubmit = async (data) => {
    if (comingSoon || soldOut) return

    setButtonIsLoading(true)
    setApiError(null)

    try {
      if (!isClubSignup) {
        if (cart?.lineItems?.length) await emptyCart(true)
        await addToCart({ lineItem: { sku, quantity: 1, options: { parts } } })
      } else {
        // @see ListingKitUpsellSelector
        await addSubscriptionToCart()
      }
    } catch (error) {
      setApiError(translate('error.api.default'))
      console.error(error, {
        source: 'ListingAddToCheckoutButton.onSubmit.addToCart',
      })
    }

    try {
      if (promoCode) await addPromoCode({ promoCode })
    } catch (error) {
      console.error(error, {
        source: 'ListingAddToCheckoutButton.onSubmit.addPromoCode',
      })
    }

    setButtonIsLoading(false)
    navigate('/checkout/')
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Button
        disabled={comingSoon || soldOut}
        type="submit"
        variant="primary"
        sx={{
          width: '100%',
          display: 'block',
        }}
      >
        {buttonIsLoading && (
          <Spinner
            data-testid="spinner"
            size="24"
            color="inherit"
            mb={-1}
            sx={{
              position: 'relative',
              top: '-2px',
              '@media (prefers-reduced-motion)': {
                circle: {
                  animationIterationCount: 8,
                },
              },
            }}
          />
        )}
        {!buttonIsLoading && (
          <Fragment>
            {displayLabel}
            {includePrice && (
              <Fragment>
                {' — '}
                {postPromoPrice && (
                  <Fragment>
                    <Text as="span">{toUsdCurrency(postPromoPrice)}</Text>{' '}
                    <Text as="span" sx={{ textDecoration: 'line-through' }}>
                      {toUsdCurrency(price)}
                    </Text>
                  </Fragment>
                )}
                {!postPromoPrice && toUsdCurrency(price)}
              </Fragment>
            )}
          </Fragment>
        )}
      </Button>
      {apiError && (
        <Text as="p" color="error" variant="formError" mt={2}>
          {apiError.toString()}
        </Text>
      )}
    </form>
  )
}

export default ListingAddToCheckoutButton
