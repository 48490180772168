/** @jsx jsx */
import { Fragment } from 'react'
import { Box, Container, Grid, Heading, jsx } from 'theme-ui'
import ListingCardSpecs from '~/components/Listing/Card/Specs'
import { getUniqueKitProducts } from '~/utils/kits'

const LandingPageProductSpecs = ({ kit, landingPage }) => {
  const { packIntro } = landingPage
  const uniqueProducts = getUniqueKitProducts(kit)
  const productCount = uniqueProducts?.length || 0

  if (productCount < 2) return <Fragment />

  return (
    <Box
      bg={!packIntro && 'backgroundMedium'}
      pt={!packIntro && [6, 7, 9]}
      pb={[7, 8, 10]}
    >
      <Container>
        <Heading
          as="h2"
          variant="title2"
          mb={[3, 4]}
          sx={{ textAlign: 'center' }}
        >
          {'Meet the Wines'}
        </Heading>
        <Grid columns={[1, 12, 2, productCount >= 3 ? 3 : null]}>
          {uniqueProducts.map((product, index) => {
            const { mainImage, sellSheetDescription, name, slug, subtitle } =
              product

            return (
              name && (
                <Box
                  key={`pack-product-${slug}-${index}`}
                  sx={{ gridColumn: [null, '3 / 11', 'auto'] }}
                >
                  <ListingCardSpecs
                    description={sellSheetDescription}
                    image={mainImage}
                    isNarrow={productCount >= 3}
                    subtitle={subtitle}
                    subtitleVariant="body3"
                    title={name}
                    titleVariant="title4"
                  />
                </Box>
              )
            )
          })}
        </Grid>
      </Container>
    </Box>
  )
}

export default LandingPageProductSpecs
