/** @jsx jsx */
import moment from 'moment'
import { getSrc } from 'gatsby-plugin-image'
import { jsx } from 'theme-ui'
import StructuredData from '~/components/StructuredData'
import { useReviews } from '~/hooks/components/use-reviews'
import { stripHtml } from '~/utils/formatters'
import { getLandingPagePostPromoPrice } from '~/utils/landing-pages'

const StructuredLandingPageData = ({ landingPage }) => {
  const { description, kit, packMainImage, title } = landingPage
  const { price, reviewListing } = kit
  const postPromoPrice = getLandingPagePostPromoPrice(landingPage, price)

  const { getProductStats } = useReviews()

  // @see https://developers.google.com/search/docs/appearance/structured-data/product
  return (
    <StructuredData
      type="Product"
      data={{
        name: title.replace('_', ' '),
        image: getSrc(packMainImage),
        description: stripHtml(description?.childMarkdownRemark?.html),
        offers: {
          '@type': 'Offer',
          availability: 'InStock',
          itemCondition: 'NewCondition',
          price: postPromoPrice ? String(postPromoPrice.toFixed(2)) : price,
          priceCurrency: 'USD',
          priceValidUntil: moment().add(1, 'year').format('YYYY-MM-DD'),
        },
      }}
      rating={getProductStats(reviewListing?.slug)}
    />
  )
}

export default StructuredLandingPageData
