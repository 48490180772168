/** @jsx jsx */
import { Fragment } from 'react'
import { Box, Button, Flex, Grid, Heading, jsx, Text } from 'theme-ui'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import CheckmarkIcon from '~/assets/images/icons/checkmark.inline.svg'
import { useAria } from '~/hooks/components/use-aria'
import { getKitBottleCount, getKitCanCount } from '~/utils/kits'

const ListingAddToCartKitSelector = ({
  children,
  allKits,
  currentKit,
  setCurrentKit,
  unavailable = false,
}) => {
  const { updateAriaLive } = useAria()
  const translate = useTranslate()

  if (!currentKit) {
    return <Fragment />
  }

  const canCount = getKitCanCount(currentKit)
  const bottleCount = getKitBottleCount(currentKit)

  const handleChange = (kit) => {
    setCurrentKit(kit)
    updateAriaLive(`${getKitCanCount(kit)} cans selected.`)
  }

  return (
    <Fragment>
      {allKits?.length > 1 && (
        <Box
          sx={{
            opacity: unavailable ? '0.6' : '1',
            pointerEvents: unavailable ? 'none' : 'auto',
          }}
        >
          <Flex
            sx={{
              display: ['block', 'flex'],
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            <Heading as="h3" mr={3} variant="text.title6">
              {`${translate('product.select_quantity')}:`}
            </Heading>
            {canCount && bottleCount && (
              <Text
                as="p"
                mt={[1, '4px']}
                variant="description"
                sx={{ color: 'greyDark' }}
              >
                {`${canCount} cans (${bottleCount} bottle equivalent)`}
              </Text>
            )}
          </Flex>
          <Grid columns={allKits.length} gap={[2, 3]} mt={3} mb={2}>
            {allKits.map((kit) => (
              <Button
                key={`${kit.sku}`}
                onClick={() => handleChange(kit)}
                variant="productAdd"
                aria-label={`${getKitCanCount(kit)} cans${
                  kit === currentKit
                    ? '; the currently selected quantity'
                    : '; click to select this quantity'
                }`}
                sx={{
                  alignItems: 'center',
                  borderColor: kit === currentKit ? 'black' : null,
                  justifyContent: 'center',
                  position: 'relative',
                  textAlign: 'center',
                }}
              >
                {`${getKitCanCount(kit)} cans`}
                {kit.additionalContentsLabel &&
                  ` + ${kit.additionalContentsLabel}`}
                {kit === currentKit && (
                  <Box
                    sx={{
                      backgroundColor: 'black',
                      borderRadius: '50%',
                      height: '24px',
                      position: 'absolute',
                      right: '-9px',
                      top: '-9px',
                      width: '24px',
                    }}
                  >
                    <CheckmarkIcon
                      sx={{
                        color: 'white',
                        position: 'relative',
                        top: '1px',
                      }}
                    />
                  </Box>
                )}
              </Button>
            ))}
          </Grid>
        </Box>
      )}
      <Box>{children}</Box>
    </Fragment>
  )
}

export default ListingAddToCartKitSelector
