/** @jsx jsx */
import { Fragment, useEffect } from 'react'
import { Box, Button, Flex, jsx, Text } from 'theme-ui'
import CheckmarkIcon from '~/assets/images/icons/checkmark.inline.svg'
import CheckmarkThinIcon from '~/assets/images/icons/checkmark-thin.inline.svg'
import { useCanClub } from '~/hooks/components/use-can-club'
import { useCatalog } from '~/hooks/components/use-catalog'
import { toUsdCurrency } from '~/utils/formatters'
import { getKitCanCount } from '~/utils/kits'
import { getLandingPagePostPromoPrice } from '~/utils/landing-pages'
import {
  getCartClubQuantity,
  getClubKitDefaultItems,
} from '~/utils/subscriptions'

const ListingKitUpsellSelector = ({
  children,
  currentKit,
  isClubSignup,
  listing,
  setCurrentKit,
  setIsClubSignup,
}) => {
  const {
    cuCallout12Club,
    cuCallout12Kit,
    cuIncludeShipping6Kit,
    cuLabel12Club,
    cuLabel12Kit,
    cuLabel6Kit,
    cuPoints12Club,
    cuPoints12Kit,
    cuPoints6Kit,
    cuPromoCode12Club,
    cuPromoPercentage12Club,
    kits,
  } = listing

  const { setJoinInterval, setProducts, setQuantity } = useCanClub()
  const { catalog } = useCatalog()

  useEffect(() => {
    if (!isClubSignup) return

    const canCount = getKitCanCount(currentKit)
    const clubQuantity = getCartClubQuantity(canCount)
    const subscriptionContent = catalog.subscriptions.find(
      ({ defaultQuantity }) => defaultQuantity === clubQuantity
    )

    if (subscriptionContent) {
      const { intervals } = subscriptionContent
      const clubItems = getClubKitDefaultItems(
        currentKit,
        subscriptionContent,
        catalog.products
      )

      setJoinInterval(intervals[0].length, intervals[0].unit[0])
      setQuantity(clubQuantity)
      setProducts(clubItems)
    }
  }, [
    catalog.products,
    catalog.subscriptions,
    currentKit,
    isClubSignup,
    setJoinInterval,
    setProducts,
    setQuantity,
  ])

  // @todo
  // Subscription price is inaccurate if kit total is not equivalent to the sum
  // of the variant part totals.
  return (
    <Box>
      {kits.map((kitOption) => {
        const canCount = getKitCanCount(kitOption)
        const { price } = kitOption
        const shippingDisplayPrice = cuIncludeShipping6Kit && 7.99

        if (canCount === 6) {
          return (
            <Box key="upsell-panel-6" mb={1}>
              <ListingKitUpsellSelectorPanel
                canCount={canCount}
                isSelected={kitOption === currentKit && !isClubSignup}
                label={cuLabel6Kit}
                name="upsell-panel-6-kit"
                onClick={() => {
                  setCurrentKit(kitOption)
                  setIsClubSignup(false)
                }}
                points={cuPoints6Kit}
                postPromoPrice={
                  getLandingPagePostPromoPrice(listing, price) +
                  shippingDisplayPrice
                }
                price={price + shippingDisplayPrice}
              />
            </Box>
          )
        } else if (canCount === 12) {
          return (
            <Fragment key="upsell-panel-12">
              <Box mb={1}>
                <ListingKitUpsellSelectorPanel
                  callout={cuCallout12Kit}
                  calloutBackground="brandSecondary"
                  canCount={canCount}
                  isSelected={kitOption === currentKit && !isClubSignup}
                  label={cuLabel12Kit}
                  name="upsell-panel-12-kit"
                  onClick={() => {
                    setCurrentKit(kitOption)
                    setIsClubSignup(false)
                  }}
                  points={cuPoints12Kit}
                  postPromoPrice={getLandingPagePostPromoPrice(listing, price)}
                  price={price}
                />
              </Box>
              <Box>
                <ListingKitUpsellSelectorPanel
                  callout={cuCallout12Club}
                  calloutBackground="brandPrimary"
                  canCount={canCount}
                  isSelected={kitOption === currentKit && isClubSignup}
                  label={cuLabel12Club}
                  name="upsell-panel-12-club"
                  onClick={() => {
                    setCurrentKit(kitOption)
                    setIsClubSignup(true)
                  }}
                  points={cuPoints12Club}
                  postPromoPrice={getLandingPagePostPromoPrice(
                    listing,
                    price,
                    cuPromoCode12Club && cuPromoPercentage12Club
                  )}
                  price={price}
                />
              </Box>
            </Fragment>
          )
        }

        return <Fragment />
      })}
      <Box mt={3}>{children}</Box>
    </Box>
  )
}

const ListingKitUpsellSelectorPanel = ({
  callout,
  calloutBackground,
  canCount,
  isSelected,
  label,
  name,
  onClick,
  points,
  postPromoPrice,
  price,
}) => {
  return (
    <Button
      bg="white"
      variant="reset"
      onClick={() => onClick()}
      sx={{
        borderRadius: '8px',
        border: '2px solid',
        borderColor: isSelected ? 'black' : 'greyLight',
        display: 'block',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      {callout && (
        <Box
          bg={calloutBackground}
          pt="4px"
          pb={1}
          sx={{ textAlign: 'center' }}
        >
          <Text
            as="p"
            variant="callout2"
            color="white"
            sx={{ fontWeight: 'bold' }}
          >
            {callout}
          </Text>
        </Box>
      )}
      <Flex p={2}>
        <Box mr="12px" pt="1px" sx={{ flexShrink: 0 }}>
          <Box
            sx={{
              backgroundColor: isSelected ? 'black' : 'transparent',
              borderRadius: '50%',
              border: '2px solid',
              borderColor: isSelected ? 'black' : 'greyLight',
              height: '24px',
              position: 'relative',
              width: '24px',
            }}
          >
            {isSelected && (
              <Box
                as="span"
                sx={{
                  color: 'white',
                  left: '50%',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateX(-50%) translateY(calc(-50% - 1px))',
                }}
              >
                <CheckmarkIcon />
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ flexGrow: '1', textAlign: 'left' }}>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Text as="p" variant="callout1" m={0} mb={1}>
              {`${label}:`}
            </Text>
            <Flex mb={1} sx={{ alignItems: 'center' }}>
              <Text
                as="p"
                variant="callout1"
                color="greyMedium"
                m={0}
                mr={1}
                sx={{
                  fontSize: ['15px', null, null, '15px'],
                  position: 'relative',
                  '&:after': {
                    borderTop: '1px solid',
                    borderColor: 'greyMedium',
                    content: '""',
                    left: '0',
                    position: 'absolute',
                    right: '0',
                    top: 'calc(50% + 3px)',
                  },
                }}
              >
                {toUsdCurrency(price)}
              </Text>
              <Text as="p" variant="callout1" m={0}>
                {toUsdCurrency(postPromoPrice, true)}
              </Text>
            </Flex>
          </Flex>
          {points && (
            <Box as="ul" m={0} p={0}>
              {points.map((point, index) => (
                <Box
                  key={`${name}-point-${index}`}
                  as="li"
                  m={0}
                  sx={{
                    fontWeight: index < 2 ? 'bold' : 'normal',
                    listStyleType: 'none',
                  }}
                >
                  <Flex
                    sx={{ alignItems: 'center' }}
                    mb={index !== points.length - 1 && '1px'}
                  >
                    <Flex
                      mr={1}
                      sx={{ flexShrink: 0, position: 'relative', top: '1px' }}
                    >
                      <CheckmarkThinIcon
                        height="12"
                        width="11"
                        sx={{ color: 'brandTertiary' }}
                      />
                    </Flex>
                    <Text as="span" variant="finePrint" color="greyDark">
                      {point.replace(
                        '{price_per_can}',
                        `${toUsdCurrency(postPromoPrice / canCount, true)}/can`
                      )}
                    </Text>
                  </Flex>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Flex>
    </Button>
  )
}

export default ListingKitUpsellSelector
