/** @jsx jsx */
import { Fragment } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Box, Container, Grid, Heading, jsx, Text } from 'theme-ui'
import LandingPageJumpCta from '~/components/LandingPage/JumpCta'
import LandingPageStars from '~/components/LandingPage/Stars'
import ImageBlobBottom from './landing-page-hero-blob-bottom.inline.svg'
import ImageBlobLeft from './landing-page-hero-blob-left.inline.svg'
import ImageBlobRight from './landing-page-hero-blob-right.inline.svg'
import ImageBlobTop from './landing-page-hero-blob-top.inline.svg'

const LandingPageHero = ({ ctaTarget, landingPage }) => {
  const { description, heroBackgroundColor, heroCta, heroImage, title } =
    landingPage
  const titlePieces = title.split('_')

  return (
    <Box
      bg={heroBackgroundColor || 'backgroundLight'}
      mb={[-2, 0]}
      sx={{ overflow: 'hidden', position: 'relative', zIndex: '1' }}
    >
      <Container>
        <Grid columns={[null, 2]} sx={{ rowGap: 0 }}>
          <Box
            my="auto"
            pt={5}
            pb={[null, 5]}
            sx={{
              position: 'relative',
              textAlign: ['center', 'left'],
              zIndex: '1',
            }}
          >
            <Heading as="h1" variant="title2" mb={2}>
              <Text as="span">{`${titlePieces[0]} `}</Text>
              {titlePieces?.[1] && (
                <Fragment>
                  <br />
                  <Text as="span">{titlePieces[1]}</Text>
                </Fragment>
              )}
            </Heading>
            {description && (
              <Box
                dangerouslySetInnerHTML={{
                  __html: description.childMarkdownRemark.html,
                }}
                sx={{
                  '& p': {
                    variant: 'text.body1',
                  },
                  '& p:first-of-type': {
                    mt: 0,
                  },
                  '& p:last-of-type': {
                    mb: 0,
                  },
                }}
              />
            )}
            <Box mt={2}>
              <LandingPageStars treatment="hero" />
            </Box>
            <Box mt={3}>
              <LandingPageJumpCta
                target={ctaTarget}
                value={heroCta || 'Buy now'}
                variant="primary"
              />
            </Box>
          </Box>
          <Box
            mt={[-6, 0]}
            ml={[-2, -3, 0]}
            mr={[-2, -3]}
            sx={{
              '@media only screen and (min-width: 1170px)': {
                mr: -6,
              },
            }}
          >
            <Box
              sx={{
                height: [null, '100%'],
                overflow: 'hidden',
                position: 'relative',
                width: [null, 'auto'],
                zIndex: '0',
              }}
            >
              <GatsbyImage
                image={heroImage.gatsbyImageData}
                alt={heroImage.description}
                loading={'eager'}
                style={{
                  height: '100%',
                  width: '100%',
                }}
              />
              <ImageBlobTop
                sx={{
                  color: heroBackgroundColor || 'backgroundLight',
                  display: ['block', 'none'],
                  height: 'auto',
                  left: '0',
                  pointerEvents: 'none',
                  position: 'absolute',
                  right: '0',
                  top: '0',
                  width: '100%',
                  zIndex: '1',
                }}
              />
              <ImageBlobBottom
                sx={{
                  bottom: '0',
                  color: 'backgroundMedium',
                  display: ['block', 'none'],
                  height: 'auto',
                  left: '0',
                  pointerEvents: 'none',
                  position: 'absolute',
                  right: '0',
                  width: '100%',
                  zIndex: '1',
                }}
              />
              <ImageBlobLeft
                sx={{
                  bottom: '0',
                  color: heroBackgroundColor || 'backgroundLight',
                  display: ['none', 'block'],
                  height: '100%',
                  left: '0',
                  pointerEvents: 'none',
                  position: 'absolute',
                  top: '0',
                  width: 'auto',
                  zIndex: '1',
                }}
              />
              <ImageBlobRight
                sx={{
                  bottom: '0',
                  color: heroBackgroundColor || 'backgroundLight',
                  display: 'none',
                  height: '100%',
                  pointerEvents: 'none',
                  position: 'absolute',
                  right: '0',
                  top: '0',
                  width: 'auto',
                  zIndex: '1',
                  '@media only screen and (min-width: 1170px)': {
                    display: 'block',
                  },
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default LandingPageHero
