/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import GiftMessage from '~/components/Cart/GiftMessage'

const ListingGiftMessage = ({ children, wrapperSx }) => {
  const { cart } = useCart()
  const { giftNote } = cart

  return (
    <Box
      sx={{
        borderBottom: '1px solid',
        borderTop: '1px solid',
        borderColor: 'greyLight',
        ...wrapperSx,
      }}
    >
      <GiftMessage giftNote={giftNote} isOverlay={false} />
    </Box>
  )
}

export default ListingGiftMessage
