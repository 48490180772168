/** @jsx jsx */
import { Fragment, useEffect, useState } from 'react'
import { Box, Container, Heading, Text, jsx } from 'theme-ui'
import { useAnalytics } from '@chordcommerce/gatsby-theme-autonomy'
import ImageCarousel from '~/components/Generic/ImageCarousel'
import LandingPagePackDetailsAdditional from '~/components/LandingPage/PackDetails/Additional'
import LandingPagePackDetailsIntro from '~/components/LandingPage/PackDetails/Intro'
import LandingPageStars from '~/components/LandingPage/Stars'
import ListingAddToCartKitSelector from '~/components/Listing/AddToCart/KitSelector'
import ListingAddToCartKitUpsellSelector from '~/components/Listing/AddToCart/KitUpsellSelector'
import ListingAddToCheckoutButton from '~/components/Listing/AddToCart/Checkout'
import ListingGiftMessage from '~/components/Listing/GiftMessage'
import PackCallout from '~/components/Pack/Callout'
import PackImages from '~/components/Pack/Page/Details/Images'
import { useCatalog } from '~/hooks/components/use-catalog'
import { getKitCanCount, getUniqueKitProducts } from '~/utils/kits'
import { getLandingPagePostPromoPrice } from '~/utils/landing-pages'
import {
  getFormattedBottleCount,
  getViewedProductFromSlug,
} from '~/utils/products'

const LandingPagePackDetails = ({ landingPage }) => {
  const { trackProductViewed } = useAnalytics()
  const { catalog } = useCatalog()

  const {
    cuEnable,
    cuPromoCode12Club,
    giftMode,
    kit,
    kits,
    packAdditional,
    packAddToCartContext,
    packCustomLabel,
    packDescription,
    packImages,
    packIntro,
    packMainImage,
    packName,
    packPreorderDate,
    packSubtitle,
    promoAutoApply,
    promoCode,
  } = landingPage
  const allPackImages = packImages?.length
    ? [packMainImage, ...packImages]
    : [packMainImage]
  const calloutPack = { customLabel: packCustomLabel }

  const [currentKit, setCurrentKit] = useState(
    kits?.[kits?.findIndex(({ sku }) => sku === kit.sku)] || kit
  )
  const [isClubSignup, setIsClubSignup] = useState(false)

  const { price, sku } = currentKit
  const kitProducts = getUniqueKitProducts(kit)
  const canQuantity = getKitCanCount(kit)
  const bottleQuantity = getFormattedBottleCount(canQuantity)
  const postPromoPrice = getLandingPagePostPromoPrice(landingPage, price)

  const viewedProduct = getViewedProductFromSlug(catalog, kit?.slug)

  useEffect(() => {
    trackProductViewed({ product: viewedProduct })
  }, [trackProductViewed, viewedProduct])

  return (
    <Box pb={[7, 9, null, 10]} pt={[0, 7, null, 9]} id="try">
      {packIntro && (
        <Box pt={[5, 0]}>
          <LandingPagePackDetailsIntro intro={packIntro} />
        </Box>
      )}
      <Container>
        <Box
          sx={{
            display: [null, 'grid'],
            gridTemplateAreas: [null, "'images text'"],
            gridTemplateColumns: [null, 'repeat(2, 1fr)'],
            columnGap: 3,
          }}
        >
          <Box
            mb={3}
            mx={-2}
            sx={{
              display: ['block', 'none'],
              gridArea: [null, 'images'],
              position: 'relative',
              '& .carousel-slider': {
                overflow: 'visible',
              },
              '& .slider-wrapper': {
                overflow: 'visible',
              },
            }}
          >
            <ImageCarousel images={allPackImages} />
            <Box sx={{ left: 0, position: 'absolute', top: 0 }}>
              <PackCallout pack={calloutPack} />
            </Box>
          </Box>
          <Box
            sx={{
              display: ['none', 'block'],
              gridArea: [null, 'images'],
            }}
          >
            <Box
              sx={{
                position: 'sticky',
                top: '78px',
              }}
            >
              <PackImages images={allPackImages} pack={calloutPack} />
            </Box>
          </Box>
          <Box sx={{ gridArea: [null, 'text'] }}>
            <Heading as="h2" variant="title2" mb={1}>
              {packName}
            </Heading>
            {packSubtitle && (
              <Text as="h3" variant="subtitle1" mb={2}>
                {packSubtitle}
              </Text>
            )}
            <Box mb={2}>
              <LandingPageStars treatment="inline" />
            </Box>
            {packDescription && (
              <Box
                dangerouslySetInnerHTML={{
                  __html: packDescription.childMarkdownRemark.html,
                }}
                sx={{
                  '& p': {
                    my: 2,
                    variant: 'text.body2',
                  },
                  '& p:first-of-type': {
                    mt: 0,
                  },
                  '& p:last-of-type': {
                    mb: 0,
                  },
                }}
              />
            )}
            {!cuEnable && (
              <Fragment>
                <Text as="p" variant="body2" my={3}>
                  <Text as="strong">{'Includes: '}</Text>
                  <Text as="span">
                    {kitProducts.map((product) => product.name).join(', ')}
                  </Text>
                </Text>
                {kits.length < 2 && canQuantity > 0 && (
                  <Text as="p" variant="body2">
                    <Text as="strong">{`${canQuantity} cans = ${bottleQuantity} bottles`}</Text>
                  </Text>
                )}
              </Fragment>
            )}
            {packAdditional?.length > 0 && (
              <Box mt={3} mx={-1}>
                <LandingPagePackDetailsAdditional cards={packAdditional} />
              </Box>
            )}
            {giftMode && (
              <Box mx={-1}>
                <ListingGiftMessage wrapperSx={{ borderTop: 'none', px: 1 }} />
              </Box>
            )}
            <Box mt={3}>
              {!cuEnable && (
                <ListingAddToCartKitSelector
                  allKits={kits || [kit]}
                  currentKit={currentKit}
                  setCurrentKit={setCurrentKit}
                >
                  <ListingAddToCheckoutButton
                    postPromoPrice={postPromoPrice}
                    preorderDate={packPreorderDate}
                    promoAutoApply={promoAutoApply}
                    promoCode={promoCode}
                    sku={sku}
                  />
                </ListingAddToCartKitSelector>
              )}
              {cuEnable && (
                <ListingAddToCartKitUpsellSelector
                  currentKit={currentKit}
                  isClubSignup={isClubSignup}
                  listing={landingPage}
                  setCurrentKit={setCurrentKit}
                  setIsClubSignup={setIsClubSignup}
                >
                  <ListingAddToCheckoutButton
                    includePrice={false}
                    isClubSignup={isClubSignup}
                    label="Checkout"
                    promoCode={
                      isClubSignup && cuPromoCode12Club
                        ? cuPromoCode12Club
                        : promoCode
                    }
                    sku={sku}
                  />
                </ListingAddToCartKitUpsellSelector>
              )}
              {packAddToCartContext && (
                <Text
                  as="p"
                  variant="description"
                  color="greyDark"
                  mt={2}
                  sx={{ textAlign: 'center' }}
                >
                  {packAddToCartContext}
                </Text>
              )}
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default LandingPagePackDetails
