/** @jsx jsx */
import { Fragment } from 'react'
import { Box, jsx } from 'theme-ui'
import ContentSection from '~/components/Content/Section'
import LandingPageHero from './Hero'
import LandingPagePackDetails from './PackDetails'
import LandingPageProductSpecs from './ProductSpecs'
import LandingPageRelatedPacks from './RelatedPacks'

const LandingPage = ({ landingPage, ctaTarget }) => {
  const {
    collectionMode,
    heroCtaDestination,
    kit,
    lowerSections,
    relatedPacks,
    relatedPacksTitle,
    upperSections,
  } = landingPage

  return (
    <Fragment>
      <LandingPageHero ctaTarget={ctaTarget} landingPage={landingPage} />
      <LandingPageSections
        ctaTarget={ctaTarget}
        heroCtaDestination={heroCtaDestination}
        sections={upperSections}
      />
      {!heroCtaDestination && (
        <LandingPageCtaDestination ctaTarget={ctaTarget} />
      )}
      <LandingPagePackDetails landingPage={landingPage} />
      {!collectionMode && (
        <LandingPageProductSpecs kit={kit} landingPage={landingPage} />
      )}
      {relatedPacks && (
        <LandingPageRelatedPacks
          landingPage={landingPage}
          title={relatedPacksTitle || 'Other Best-Sellers'}
        />
      )}
      <LandingPageSections
        ctaTarget={ctaTarget}
        heroCtaDestination={heroCtaDestination}
        sections={lowerSections}
      />
    </Fragment>
  )
}

const LandingPageSections = ({ ctaTarget, heroCtaDestination, sections }) => {
  return sections?.map((section) => (
    <Fragment key={`landing-page-section-${section.id}`}>
      {heroCtaDestination?.id === section.id && (
        <LandingPageCtaDestination ctaTarget={ctaTarget} />
      )}
      <ContentSection key={section.id} section={section} />
    </Fragment>
  ))
}

const LandingPageCtaDestination = ({ ctaTarget }) => {
  return <Box ref={ctaTarget} sx={{ position: 'relative', top: '-64px' }} />
}

export default LandingPage
