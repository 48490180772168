/** @jsx jsx */
import { Box, Container, Grid, Heading, jsx } from 'theme-ui'

const LandingPagePackDetailsIntro = ({ intro }) => {
  const { description, title } = intro

  return (
    <Box pb={[4, 5, null, 6]}>
      <Container>
        <Grid columns={[1, null, 12]}>
          <Box
            sx={{
              gridColumn: [null, null, '2 / 12', '3 / 11'],
              textAlign: 'center',
            }}
          >
            {title && (
              <Heading as="h2" variant="title2" mb={2}>
                {title}
              </Heading>
            )}
            {description && (
              <Box
                sx={{
                  variant: 'text.body1',
                  p: { m: 0 },
                  a: {
                    variant: 'text.link',
                  },
                }}
                dangerouslySetInnerHTML={{
                  __html: description.childMarkdownRemark.html,
                }}
              />
            )}
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default LandingPagePackDetailsIntro
