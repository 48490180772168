export const getLandingPagePostPromoPrice = (
  landingPage,
  price,
  promoPercentageOverride
) => {
  const { promoFixedAmount, promoPercentage } = landingPage

  if (promoPercentageOverride) {
    return ((100 - promoPercentageOverride) * price) / 100
  } else if (promoPercentage) {
    return ((100 - promoPercentage) * price) / 100
  } else if (promoFixedAmount) {
    return price - promoFixedAmount
  }
}
