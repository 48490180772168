/** @jsx jsx */
import { Box, jsx } from 'theme-ui'
import CollapsiblePanel from '~/components/Generic/CollapsiblePanel'

const LandingPagePackDetailsAdditional = ({ cards }) => {
  return (
    <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'greyLight' }}>
      {cards.map((panel, index) => (
        <Box
          key={`pack-details-additional-${index}`}
          sx={{ borderTop: '1px solid', borderTopColor: 'greyLight' }}
        >
          <CollapsiblePanel
            initiallyCollapsed={true}
            title={panel.title}
            titleSx={{ fontWeight: 'bold' }}
            titleVariant="body3"
          >
            <Box
              dangerouslySetInnerHTML={{
                __html: panel.description.childMarkdownRemark.html,
              }}
              sx={{
                '& p': {
                  my: 2,
                  variant: 'text.body3',
                },
                '& ul': {
                  my: 0,
                },
                '& li': {
                  my: 1,
                  variant: 'text.body3',
                },
                '& p:first-of-type, & li:first-of-type': {
                  mt: 0,
                },
                '& p:last-of-type, & li:last-of-type': {
                  mb: 0,
                },
              }}
            />
          </CollapsiblePanel>
        </Box>
      ))}
    </Box>
  )
}

export default LandingPagePackDetailsAdditional
