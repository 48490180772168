/** @jsx jsx */
import { Box, Button, Flex, jsx, Text } from 'theme-ui'
import StarIcon from '~/assets/images/icons/star.inline.svg'
import { useReviews } from '~/hooks/components/use-reviews'

const LandingPageStars = ({ treatment }) => {
  const { getAllStats } = useReviews()
  const allStats = getAllStats()
  const roundedCount = allStats?.count && Math.round(allStats.count / 100) * 100

  let wrapperSx = {}
  if (treatment === 'hero') {
    wrapperSx = { flexDirection: 'column' }
  }
  if (treatment === 'inline') {
    wrapperSx = { alignItems: 'center', flexFlow: 'row wrap' }
  }

  const handleStarsClick = () => {
    const fullReviews = document.getElementById('reviews')

    if (fullReviews) {
      fullReviews.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      })
    }
  }

  return (
    <Button
      onClick={(e) => handleStarsClick()}
      variant="reset"
      sx={{ cursor: 'pointer', textAlign: ['center', 'left'] }}
    >
      <Flex sx={wrapperSx}>
        <Box mr={1}>
          {[...Array(5)].map((x, i) => (
            <StarIcon key={`hero-star-${i}`} sx={{ mx: '2px' }} />
          ))}
        </Box>
        <Text as="p" m={0} variant="body3">
          {`Maker has ${roundedCount || '1000'}+ 5 star reviews!`}
        </Text>
      </Flex>
    </Button>
  )
}

export default LandingPageStars
