/** @jsx jsx */
import { Box, Container, Grid, Heading, jsx } from 'theme-ui'
import PackCard from '~/components/Pack/Card'
import PackCardLanding from '~/components/Pack/Card/Landing'

const LandingPageRelatedPacks = ({ landingPage, title }) => {
  const { collectionMode, relatedPacks } = landingPage
  const PackCardComponent = !collectionMode ? PackCardLanding : PackCard

  return (
    <Box pt={[3, 4, null, 6]} pb={[4, 5, null, 7]} bg="backgroundLight">
      <Container>
        {title && (
          <Heading as="h2" variant="title2" mb={3} sx={{ textAlign: 'center' }}>
            {title}
          </Heading>
        )}
        <Grid columns={[1, 3]} sx={{ rowGap: 9 }}>
          {relatedPacks.map((pack, index) => {
            return (
              <PackCardComponent
                hasGiftMessage={collectionMode}
                key={`related-pack-${index}`}
                landingPage={landingPage}
                pack={pack}
              />
            )
          })}
        </Grid>
      </Container>
    </Box>
  )
}

export default LandingPageRelatedPacks
